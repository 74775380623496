<template>
<!-- <router-view v-if="fronteggLoaded"></router-view>-->
 <router-view></router-view>
</template>

<script>
// import useGlobalData from "@/state/global-data";
// import axios from "axios";

export default {
  name: 'App',
  data() {
    return {
      // ...this.mapAuthState(),
    };
  },
  beforeMount() {
    const theme = localStorage.getItem('theme');
    if (theme) {
      document.documentElement.setAttribute("data-layout-mode", theme);
    }
    // const interval = setInterval(() => {
    //   if (window.location.href.includes("/login")) return;
    //   if (!this.authState.isLoading) {
    //     clearInterval(interval);
    //     if (this.authState.user) {
    //       // Add access token as bearer
    //       axios.defaults.headers.common['authorization'] = 'Bearer ' + this.authState.user.accessToken
    //       axios.get(process.env.VUE_APP_API_BASE_URL+'/api/portal/auth/info').then((data) => {
    //         const {setUserEmail} = useGlobalData();
    //         setUserEmail(data.data.email);
    //       }).catch((er) => {
    //         console.log(er)
    //       });
    //     } else {
    //       window.location.href = '/login';
    //     }
    //   }
    // }, 50);
  }
}
</script>
