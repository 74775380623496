import { ref } from "vue";

const userEmail = ref(null)
const userType = ref(null)

export default function useGlobalData(){

    function setUserEmail(val){
        userEmail.value=val;
    }

    function setUserType(val){
        userType.value=val;
    }

    return {userEmail, setUserEmail, userType, setUserType}
}
